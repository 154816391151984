import classNames from 'classnames';
import Footer from '../Footer';
import styles from './Page.module.scss';

const Page = (props) => {
  const { className, wide = false, userDetails, align } = props;
  const isGuest = !userDetails;
  return (
    <div
      className={classNames(
        styles['page'],
        { [className]: className !== '' },
        { wide, [styles[`align-${align}`]]: !!align },
        { guest: isGuest }
      )}
    >
      <div className={classNames(styles['page-inner'], props.pageInner)}>
        <div
          className={classNames(
            styles['page-inner-content'],
            props.pageInnerContent
          )}
        >
          {props.children}
        </div>
      </div>
      {props.footer || <Footer />}
    </div>
  );
};

export default Page;
