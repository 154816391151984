import { buildFAQPageUrl } from '@zorba-shared/core/Services/urlBuilderService';
import { Collapse } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';
import parentStyles from '../../index.module.scss';
import styles from './index.module.scss';

const { Panel } = Collapse;
export default (props) => {
  const { showAnswerLink = true, variantData, title, titleClass, sectionInnerClass, backgroundColor, variant = "default" } =
    props;

  function callback(key) {}
  
  if (!variantData) return null;
  return (
    <div
      className={classNames(parentStyles.section, styles['faq-section'], styles[variant])}
      style={{ backgroundColor }}
    >
      <div
        className={classNames(
          parentStyles['section-inner'],
          styles['section-inner'],
          sectionInnerClass
        )}
      >
        <div className={styles.left}>
          <h3 className={classNames(parentStyles['section-title'], titleClass)}>
            {title || 'Still have questions?'}
          </h3>
        </div>
        <div className={styles.right}>
          <Collapse onChange={callback} className="zorba-collapse">
            {Object.keys(variantData.faq).map((questionKey) => {
              const questionObj = variantData.faq[questionKey];
              return (
                <Panel header={questionObj.title} key={questionKey} forceRender>
                  <div dangerouslySetInnerHTML={{ __html: questionObj.desc }} />
                  {showAnswerLink && <a
                    className={styles['share-question-btn']}
                    href={buildFAQPageUrl(questionKey)}
                  >
                    Share Answer
                  </a>}
                </Panel>
              );
            })}
          </Collapse>
        </div>
      </div>
    </div>
  );
};
