import { Modal } from 'antd';
import classNames from 'classnames';
import styles from './Modal.module.scss';
export default (props) => (
  <Modal
    {...props}
    className={classNames(styles['modal-component'], {
      [styles[props.className]]: !!props.className,
    })}
  >
    {props.children}
  </Modal>
);
