import classNames from 'classnames';
import { AVAILABLE_STATES } from '../../../constants/places';
import styles from './Footer.module.scss';

const DEEFAULT_STATES = AVAILABLE_STATES.map((state) => ({
  prop: state,
  link: `/sell/${state.replace(' ', '-').toLowerCase()}`,
}));

export default (props) => {
  const { placeLinks, placeTitle } = props;

  return (
    <div className={classNames(styles.footer)}>
      {/* <div className={classNames(styles['section-inner'], styles.links)}>
        <h4>{placeTitle || `Sell your house fast in 20+ states`}</h4>
        <div className={styles['items-wrapper']}>
          <ul className={styles.items}>
            {(placeLinks || DEEFAULT_STATES).map((state, idx) => (
              <li key={idx} className={styles['link-item']}>
                <a href={state.link}>{state.prop}</a>
              </li>
            ))}
          </ul>
        </div>
      </div> */}

      <div className={styles['section-inner']}>
        <div className={classNames(styles.left, styles['logo-wrapper'])}>
          <img
            className={styles['footer-logo']}
            src="/img/brand/zorba-logo-black.png"
            alt="Zorba"
          />
          <div className={styles['section-note']}>
          © 2024 Zorba
          <img src="https://imgdrop.imgix.net/2cfd25967a794adabcc97873553da5ab.png" style={{width: 40, marginLeft: 35}} />
          </div>
          
        </div>
        <div className={classNames(styles.right, styles['social-links'])}>
          {/* <i classNam/e="fa fa-instagram-square" /> */}
          <a
            href="https://www.instagram.com/zorba.realestate"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/img/layout/footer/instagram.svg" alt="Instagram" style={{height: 25}} />
          </a>
          <a
            href="https://www.facebook.com/getzorba"
            target="_blank"
            className={styles['facebook-btn']}
            rel="noreferrer"
          >
            <img src="/img/layout/footer/facebook.svg" alt="Facebook" />
          </a>
          <a
            href="https://www.linkedin.com/company/getzorba"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/img/layout/footer/linkedin.svg" alt="Linkedin" />
          </a>
        </div>
      </div>


      <div className={styles['section-inner']}>

        <div className={styles['section-note-commitment']}>
        Contact Zorba Brokerage: Zorba Florida Realty Group LLC LIC #CQ1064981 and Zorba Inc: 7901 4th St N STE 300, St. Petersburg, FL 33702. Zorba Brokerage holds licenses in multiple states through SFR Realty LLC. Phone: 917-267-8655
        <br/><br/>
          Our Commitment to Accessibility <br /> Zorba is committed to making
          our website accessible and user friendly to all. While we are
          constantly working to improve, we welcome your feedback and
          accommodation requests. If you are having difficulty accessing or
          navigating our website, or if you have any suggestions to improve
          accessibility, please email our team.
        </div>
      </div>

      <div
        className={classNames(
          styles['section-inner'],
          styles['terms-privacy-links']
        )}
      >
        <div className={styles['single-link']}>© Zorba, Inc.</div>
        <div className={styles['single-link']}>
          <a href="/terms">Terms of Use</a>
        </div>
        <div className={styles['single-link']}>
          <a href="/privacy">Privacy Policy</a>
        </div>
      </div>
    </div>
  );
};
