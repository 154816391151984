
export const SELLER_QUESTIONS = {
  'am-i-obligated-to-accept-your-offer': {
    title: 'Is there any cost or obligation if I request an offer?',
    desc: 'Receiving an offer from Zorba on your home is completely free, and comes with absolutely no obligation to sell. To get started, enter your address at the top of this page, and we’ll walk you through some quick questions about your home’s condition, features, and upgrades.',
  },
  'are-there-any-costs-involved': {
    title: 'Are there any costs involved?',
    desc: `Requesting an offer from Zorba is completely free. 
        If you choose to accept an offer from one of our buyers, any transaction fees will be clearly explained in the offer.`,
  },

  'do-i-or-tenants-have-to-move-right-away': {
    title: 'Do I/tenants have to move right away?',
    desc: 'No. We will work with you to pick a move date that works for your schedule, typically within 60 days of closing.',
  },
  'whats-the-catch': {
    title: 'Sounds too good to be true - what is the catch?',
    desc: 'People often question our business model, asking how we can provide competitive offers on homes while still making a profit. However, our approach is different from flippers or other investors who buy low and sell high. Our model is fee-based, using recent comparable home sales to create a competitive offer. If you choose to sell to us, we deduct a service fee from the sale proceeds, much like an agent’s commission in a traditional sale. We purchase hundreds of homes monthly, assisting homeowners nationwide in moving on to their next phase.',
  },
};

export const AGENT_QUESTIONS = {
  'how-do-you-compensate-agents': {
    title: 'How do you compensate agents?',
    desc: `Earn 1% on eligible representation, on top of any seller paid commission. For instance, if you earn a 3% commission from the seller, your total earnings from the transaction could amount to 4%.`,
  },
  'just-submitted-property': {
    title:
      'I just submitted a property - what’s next? How does your process work?',
    desc: 'Once you submit a property, you will receive an automated initial offer. One of our market directors will be in touch within 24 hours (usually within just a couple hours!) to finilize the cash offer based on the information submitted. If it works for your client, we will send a contract, and we’ll schedule the inspection for that week. From there, we’ll work with you and our title company partner to complete a quick close.',
  },
  'will-you-buy-turn-key-property': {
    title: 'I’ve got a turn-key property - will you buy it?',
    desc: `Yes! That's our ideal option.`,
  },
  'are-you-offers-non-contingent': {
    title: 'Are your offers non-contingent?',
    desc: 'Our renovation partners perform a diligence walk once the home is under contract in order to build their renovation scope. We include a healthy diligence budget in our offers, so barring any major unforeseen items, we’ll work toward a fast close.',
  },
  'i-just-submitted-a-property': {
    title:
      'I just submitted a property - what’s next? How does your process work?',
    desc: `Once you submit a property, you will receive an automated initial offer. One of our market directors will be in
  touch within 24 hours (usually within just a couple hours!) to finalize the cash offer based on the information
  submitted. If it works for you, We will send you a contract, and we’ll schedule the inspection for that week. From there, we’ll
  work with you and our title company partner to complete a quick close.
  `,
  },
  'do-you-buy-assignable-contracts': {
    title: 'Do you buy assignable contracts?',
    desc: 'YES!',
  },
  'are-you-okay-with-double-closings': {
    title: 'Are you okay with double closings?',
    desc: `Yes! We’ll work with you to make sure we have the proper paperwork so our title company can get a clean and
  insurable title.`,
  },
  'how-quickly-can-you-close': {
    title: 'How quickly can you close?',
    desc: 'Very. Typically 7-28 days.',
  },
  'inspection-contingency': {
    title: 'Inspection contingency',
    desc: `In many cases, we can perform a pre-inspection; however, on a case-by-case basis, we may require 3-5 days of contingency. This information will be delivered along with our offer.`,
  },
  'how-are-you-structured': {
    title: 'How are you structured?',
    desc: `Zorba is an investment management platform. We source and manage properties for pension and investment funds. Our
  headquarters are in Florida, and we operate across multiple states. Zorba, Inc. holds real estate brokerage licenses in
  multiple states. See a list at the bottom of the page.`,
  },
  'is-the-title-company-investor-friendly': {
    title: 'Is the title company you work with investor friendly?',
    desc: `Absolutely! We specialize in off-market and wholesaler deals and so do the title companies we partner with. They’re
  okay with double closes and B2C assignments.`,
  },
  'are-your-offers-non-contingent': {
    title: 'Are your offers non-contingent?',
    desc: `Our renovation partners perform a diligence walk in order to build their renovation
  scope. We include a healthy diligence budget in our offers, so barring any major unforeseen items, we’ll work toward a
  fast close.`,
  },
  'how-does-the-revenue-share-program-work': {
    title: 'How does the Revenue Share Program work?',
    desc: `Get excited! We truly have one of the best Revenue Share structures out there! Upon accepting the terms of use, you’ll
  receive a personal link that can be shared with fellow investors or referrals. For every property we purchase from your
  referred user, you’ll earn $1,000! You can easily track your referrals, property submissions, and
  earnings through your dashboard.`,
  },
  'how-to-request-payout-for-revenue-share-earnings': {
    title: 'How to request payout for Revenue Share earnings?',
    desc: `Payouts for revenue share earnings can be requested once every 30 days by clicking the payout button. To be eligible
  for a payout, the minimum balance must be $1,000. The payout amount will be based on the terms and conditions of the
  Revenue Share program, which can be found on the Revenue Share program page.`,
  },
  'what-should-i-do-if-my-account-is-still-awaiting-approval': {
    title: 'What should I do if my account is still awaiting approval?',
    desc: `If your account has not been verified within 2 business days, please email us at acquisitions@zorbapartners.com
  with your company name, the email used for signup, and a link to your website or Facebook page.`,
  },
  'what-is-your-typical-timeline-for-closing-a-deal': {
    title: 'What is your typical timeline for closing a deal?',
    desc: `Our standard closing time is 21 days. However, in certain cases, we might be able to expedite the process and
  close within 14 days, provided the title is free and clear.`,
  },
  'is-it-possible-for-you-to-provide-proof-of-funds': {
    title: 'Is it possible for you to provide proof of funds?',
    desc:
      'Yes, once the property passes underwriting, we will provide proof of funds along with the offer via email.',
  },
  'how-can-i-submit-details-and-information-about-my-properties-to-you': {
    title:
      'How can I submit details and information about my properties to you?',
    desc: `Please submit your deals via our website at https://getzorba.com/agents. This is the fastest way to get our offers.`,
  },
  'do-you-conduct-property-showings-in-person-or-based-on-virtual-assessments': {
    title:
      'Do you conduct property showings in person, or do you make offers based on virtual assessments?',
    desc:
      'We conduct inspections, and in many cases can do pre-inspections with 0-day due diligence.',
  },
  'what-percentage-of-the-after-repair-value-do-you-typically-offer-when-buying-properties': {
    title:
      'What percentage of the After Repair Value (ARV) do you typically offer when buying properties?',
    desc:
      'We purchase properties with the intention of renting them out. We seek cap rates of 5.15% or higher.',
  },
  'what-is-your-maximum-allowed-earnest-money-deposit': {
    title: 'What is your maximum allowed earnest money deposit?',
    desc: `$5,000 is are maximum allowed earnest money deposit.`,
  },
  'are-there-any-post-occupancy-options-available': {
    title: 'Are there any post-occupancy options available?',
    desc: `We prefer to have the house vacant at closing. However, on a case-by-case basis, we are able to sign a short-term lease agreement with the owner or tenant for up to 60 days.`,
  },
  'does-zorba-primarily-focus-on-buying-properties-to-manage-as-long-term-rentals': {
    title:
      'Does Zorba primarily focus on buying properties to manage as long-term rentals?',
    desc: 'Yes, we buy houses so that we can rent them out.',
  },
  'could-you-describe-your-typical-exit-strategy': {
    title: 'Could you describe your typical exit strategy?',
    desc: 'Portfolio sale.',
  },
  'for-calculating-the-cap-rate-what-expenses-should-be-considered-for-deduction-from-the-rental-income': {
    title:
      'For calculating the cap rate, what expenses should be considered for deduction from the rental income?',
    desc: 'At the moment, we do not share our pro-forma.',
  },
  'are-you-open-to-purchasing-properties-directly-from-builders': {
    title: 'Are you open to purchasing properties directly from builders?',
    desc:
      'Sure, in that case we will send you a purchase and sale agreement instead of an assignment of contract.',
  },
};

export const BUYER_QUESTIONS = {
  'how-does-zorba-work-for-buyers': {
    title: 'How does Zorba work for buyers?',
    desc: `Zorba matches real estate investors directly with highly motivated sellers in 3 easy steps:<br/>

   1. Enter your preferred buying criteria and zip coverage.<br/> 
   2. Receive a notification as soon as a vetted property that fits your criteria is available. No MLS — exclusive off-market listings only.<br/>
   3. Place your offer<br/>

 `,
  },
  'what-happens-if-the-seller-accepts-my-offer': {
    title: 'What happens once the seller accepts my offer?',
    desc: `1. Once the seller accepts the initial offer, you can use the platform to schedule an inspection.<br/>
2. After the walkthrough, you will be able to adjust your final offer.<br/>
3. If the seller accepts the final offer, a PSA is signed and the property moves to closing.<br/>

<br/>Note that this is not an auction. The seller will see all offers and will pick the right one for them. To increase the chances of your offer being selected by the seller, put forward your best offer.,
    `,
  },
  'how-do-i-submit-an-offer-on-a-zorba-home': {
    title: 'How do I submit an offer on a Zorba home?',
    desc: `Each property page has a “Place offer” button.
    Once you click the button and submit your offer, The seller will receive it within seconds. You'll be notified once they accept or reject your offer.
    Please note that you have to be logged in to your Zorba account in order to place offers.
    `,
  },
  'can-i-send-my-inspector-to-the-property': {
    title: 'Can I send my inspector to the property?',
    desc: `Yes, if your initial offer is accepted by the owner, you will have the option to inspect the property before closing.`,
  },
  'how-much-does-it-cost': {
    title: 'How much does it cost?',
    desc: `Once you sign up and validate your account on Zorba, you may place offers for free.
    We only take a 4% fee on successful purchases.`,
  },
  'is-the-contract-assignable': {
    title: 'Is the contract assignable?',
    desc: `No, when you sign a purchase and sale agreement it will be signed directly with the seller to make the transaction as smooth as possible.`,
  },
};

export const INVESTOR_QUESTIONS = {
  'i-just-submitted-a-property': {
    title:
      'I just submitted a property - what’s next? How does your process work?',
    desc: `Once you submit a property, you will receive an automated initial offer. One of our market directors will be in
  touch within 24 hours (usually within just a couple hours!) to finalize the cash offer based on the information
  submitted. If it works for you, We will send you a contract, and we’ll schedule the inspection for that week. From there, we’ll
  work with you and our title company partner to complete a quick close.
  `,
  },
  'do-you-buy-assignment-contracts': {
    title: 'Do you buy assignment contracts?',
    desc: 'YES!',
  },
  'are-you-okay-with-double-closings': {
    title: 'Are you okay with double closings?',
    desc: `Yes! We’ll work with you to make sure we have the proper paperwork so our title company can get a clean and
  insurable title.`,
  },
  'how-quickly-can-you-close': {
    title: 'How quickly can you close?',
    desc: 'Very. Typically 7-28 days.',
  },
  'inspection-contingency': {
    title: 'Inspection contingency',
    desc: `In many cases, we can perform a pre-inspection; however, on a case-by-case basis, we may require 3-5 days of contingency. This information will be delivered along with our offer.`,
  },
  'how-are-you-structured': {
    title: 'How are you structured?',
    desc: `Zorba is an investment management platform. We source and manage properties for pension and investment funds. Our
  headquarters are in Florida, and we operate across multiple states. Zorba, Inc. holds real estate brokerage licenses in
  multiple states in cooperation with SFR Realty, LLC. See a list at the bottom of the page.`,
  },
  'is-the-title-company-investor-friendly': {
    title: 'Is the title company you work with investor friendly?',
    desc: `Absolutely! We specialize in off-market and wholesaler deals and so do the title companies we partner with. They’re
  okay with double closes and B2C assignments.`,
  },
  'are-your-offers-non-contingent': {
    title: 'Are your offers non-contingent?',
    desc: `Our renovation partners perform a diligence walk in order to build their renovation
  scope. We include a healthy diligence budget in our offers, so barring any major unforeseen items, we’ll work toward a
  fast close.`,
  },
  'how-does-the-revenue-share-program-work': {
    title: 'How does the Revenue Share Program work?',
    desc: `Get excited! We truly have one of the best Revenue Share structures out there! Upon accepting the terms of use, you’ll
  receive a personal link that can be shared with fellow investors or referrals. For every property we purchase from your
  referred user, you’ll earn $1,000! You can easily track your referrals, property submissions, and
  earnings through your dashboard.`,
  },
  'how-to-request-payout-for-revenue-share-earnings': {
    title: 'How to request payout for Revenue Share earnings?',
    desc: `Payouts for revenue share earnings can be requested once every 30 days by clicking the payout button. To be eligible
  for a payout, the minimum balance must be $1,000. The payout amount will be based on the terms and conditions of the
  Revenue Share program, which can be found on the Revenue Share program page.`,
  },
  'what-should-i-do-if-my-account-is-still-awaiting-approval': {
    title: 'What should I do if my account is still awaiting approval?',
    desc: `If your account has not been verified within 2 business days, please email us at acquisitions@zorbapartners.com
  with your company name, the email used for signup, and a link to your website or Facebook page.`,
  },
  'what-is-your-typical-timeline-for-closing-a-deal': {
    title: 'What is your typical timeline for closing a deal?',
    desc: `Our standard closing time is 21 days. However, in certain cases, we might be able to expedite the process and
  close within 14 days, provided the title is free and clear.`,
  },
  'is-it-possible-for-you-to-provide-proof-of-funds': {
    title: 'Is it possible for you to provide proof of funds?',
    desc:
      'Yes, once the property passes underwriting, we will provide proof of funds along with the offer via email.',
  },
  'how-can-i-submit-details-and-information-about-my-properties-to-you': {
    title:
      'How can I submit details and information about my properties to you?',
    desc: `Please submit your deals via our website at https://getzorba.com/investors. This is the fastest way to get our offers.`,
  },
  'do-you-conduct-property-showings-in-person-or-based-on-virtual-assessments': {
    title:
      'Do you conduct property showings in person, or do you make offers based on virtual assessments?',
    desc:
      'We conduct inspections, and in many cases can do pre-inspections with 0-day due diligence.',
  },
  'what-percentage-of-the-after-repair-value-do-you-typically-offer-when-buying-properties': {
    title:
      'What percentage of the After Repair Value (ARV) do you typically offer when buying properties?',
    desc:
      'We purchase properties with the intention of renting them out. We seek cap rates of 5.15% or higher.',
  },
  'what-is-your-maximum-allowed-earnest-money-deposit': {
    title: 'What is your maximum allowed earnest money deposit?',
    desc: `$5,000 is are maximum allowed earnest money deposit.`,
  },
  'are-there-any-post-occupancy-options-available': {
    title: 'Are there any post-occupancy options available?',
    desc: `We prefer to have the house vacant at closing. However, on a case-by-case basis, we are able to sign a short-term lease agreement with the owner or tenant for up to 60 days.`,
  },
  'does-zorba-primarily-focus-on-buying-properties-to-manage-as-long-term-rentals': {
    title:
      'Does Zorba primarily focus on buying properties to manage as long-term rentals?',
    desc: 'Yes, we buy houses so that we can rent them out.',
  },
  'could-you-describe-your-typical-exit-strategy': {
    title: 'Could you describe your typical exit strategy?',
    desc: 'Portfolio sale.',
  },
  'for-calculating-the-cap-rate-what-expenses-should-be-considered-for-deduction-from-the-rental-income': {
    title:
      'For calculating the cap rate, what expenses should be considered for deduction from the rental income?',
    desc: 'At the moment, we do not share our pro-forma.',
  },
  'are-you-open-to-purchasing-properties-directly-from-builders': {
    title: 'Are you open to purchasing properties directly from builders?',
    desc:
      'Sure, in that case we will send you a purchase and sale agreement instead of an assignment of contract.',
  },
};



export const FAQ_QUESTIONS = {
  ...SELLER_QUESTIONS,
  ...BUYER_QUESTIONS,
};

export const DATA_PLATFORM_QUESTIONS = {
  'what-support-do-you-offer-for-new-users': {
    title: 'What support do you offer for new users?',
    desc: `Our founders can provide direct, personalized onboarding and are available to answer any questions within hours. We're committed to your success.`
  },
  'can-i-customize-the-analytics-to-my-investment-criteria': {
    title: "Can I customize the analytics for my specific investment criteria?",
    desc: "Yes, our platform allows you to set custom parameters and filters to align with your unique investment strategy and goals."
  },
  'where-do-you-source-your-data': {
    title: 'Where do you source your data?',
    desc: `Our real estate data is sourced by Cherre, complemented by propietary datasets we collect and clean using trained AI-models.`,
  },
  'how-often-your-data-is-updated': {
    title: 'How often your data is updated?',
    desc: `All real estate and demographic data is updated weekly,ensuring you get the most updated image of today's market.`,
  },
  'whats-the-roi': {
    title: `What's the ROI?`,
    desc: `Most users think of ROI in terms of the number of deals the software helps them win they wouldn't otherwise. Winning a single deal is often enough to cover the cost of an annual subscription to the platform.`,
  },
  'what-is-an-active-investor': {
    title: "What is an active investor?",
    desc: `An investor who purchased at least one investment property in the past 90 days in your market.`
  },
}

export const DATA_QUESTIONS = {
  'what-is-an-active-investor': {
    title: "What is an active investor?",
    desc: `An investor who purchased at least one investment property in the past 90 days in your market.`
  },
  'how-can-you-tell-this-buyer-would-by-my-property': {
    title: 'How can you tell this buyer would buy my property?',
    desc: 'A matching buyer is an investor who recently purchased a property with the same features as your property. An investor would feel more comfortable purchasing a property similar to one they recently bought because their decision-making and offer process is faster.',
  },

  'where-do-you-source-your-data': {
    title: 'Where do you source your data?',
    desc: `Our real estate data is sourced by Cherre, complemented by propietary datasets we collect and clean using trained AI-models.`,
  },
  'how-often-your-data-is-updated': {
    title: 'How often your data is updated?',
    desc: `All real estate and demographic data is updated weekly,ensuring you get the most updated image of today's market.`,
  },
  'whats-the-roi': {
    title: `What's the ROI?`,
    desc: `Most users think of ROI in terms of the number of deals the software helps them win they wouldn't otherwise. Winning a single deal is often enough to cover the cost of an annual subscription to the platform.`,
  },
}